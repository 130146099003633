import { createGlobalState } from '@vueuse/core';
import type { TraderProfile, TraderVerification } from '@/src/features/user/types';

const useStore = createGlobalState(() => {
	const traderProfile = ref<TraderProfile | null>(null);
	const isModalOpen = ref(false);
	const isBlockAccountModalOpen = ref(false);
	const isEmailEdit = ref(false);
	const isChangePasswordEdit = ref(false);
	const isLoginEdit = ref(false);
	const isPersonalEdit = ref(false);
	const isPersonalityEdit = ref(false);

	return {
		traderProfile,
		isModalOpen,
		isBlockAccountModalOpen,
		isEmailEdit,
		isChangePasswordEdit,
		isLoginEdit,
		isPersonalEdit,
		isPersonalityEdit,
	};
});

export const useIsProfileModalOpen = () => {
	const { isModalOpen } = useStore();
	return computed(() => isModalOpen.value);
};

export const saveIsModalOpen = (isOpen: boolean) =>
	(useStore().isModalOpen.value = isOpen);

export const useIsBlockAccountModalOpen = () => {
	const { isBlockAccountModalOpen } = useStore();
	return computed(() => isBlockAccountModalOpen.value);
};

export const saveIsBlockAccountModalOpen = (isOpen: boolean) =>
	(useStore().isBlockAccountModalOpen.value = isOpen);

export const useIsEmailEdit = () => {
	const { isEmailEdit } = useStore();
	return computed(() => isEmailEdit.value);
};

export const saveIsEmailEdit = (isEdit: boolean) =>
	(useStore().isEmailEdit.value = isEdit);

export const useIsChangePasswordEdit = () => {
	const { isChangePasswordEdit } = useStore();
	return computed(() => isChangePasswordEdit.value);
};

export const saveIsChangePasswordEdit = (isEdit: boolean) =>
	(useStore().isChangePasswordEdit.value = isEdit);

export const useIsLoginEdit = () => {
	const { isLoginEdit } = useStore();
	return computed(() => isLoginEdit.value);
};

export const saveIsLoginEdit = (isEdit: boolean) =>
	(useStore().isLoginEdit.value = isEdit);

export const saveTraderProfile = (user: TraderProfile | null) => {
	useStore().traderProfile.value = user;
};

export const saveTraderProfileVerification = (verification: TraderVerification) =>
	useStore().traderProfile.value.verification = verification;

export const readTraderProfile = () => {
	const { traderProfile } = useStore();
	return traderProfile.value;
};

export const useTraderProfile = () => {
	const { traderProfile } = useStore();
	return computed(() => traderProfile.value);
};

export const useIsPersonalEdit = () => {
	const { isPersonalEdit } = useStore();
	return computed(() => isPersonalEdit.value);
};

export const saveIsPersonalEdit = (isEdit: boolean) =>
	(useStore().isPersonalEdit.value = isEdit);

export const useIsPersonalityEdit = () => {
	const { isPersonalityEdit } = useStore();
	return computed(() => isPersonalityEdit.value);
};

export const saveIsPersonalityEdit = (isEdit: boolean) =>
	(useStore().isPersonalityEdit.value = isEdit);