import { createGlobalState } from '@vueuse/core';
import type { UserCurrent } from '../../types';

const useStore = createGlobalState(() => {
	const user = ref<UserCurrent | null>(null);

	return {
		user,
	};
});

export const saveUser = (user: UserCurrent | null) => {
	useStore().user.value = user;
};

export const saveUserPhoto = (photo: string) => {
	useStore().user.value.photo = photo;
};

export const readUser = () => {
	const { user } = useStore();
	return user.value;
};

export const useUser = () => {
	const { user } = useStore();
	return computed(() => user.value);
};

export const useUserRequired = () => {
	const { user } = useStore();
	return computed(() => user.value as UserCurrent);
};
