import { all, createValidationSchema, rules } from '@legacy-studio/library';
import type { EditPersonalForm } from '../../types';

export const editPersonalSchema = createValidationSchema<EditPersonalForm>({
	firstName: ({ firstName }) => rules.required(firstName),
	lastName: ({ lastName }) => rules.required(lastName),
	gender: ({ gender }) => rules.required(gender),
	phone: ({ phone }) => all([rules.phone, rules.required])(phone),
	country: ({ country }) => rules.required(country),
	birthday: ({ birthday }) => rules.required(birthday),
	citizenship: ({ citizenship }) => rules.required(citizenship),
	city: ({ city }) => rules.required(city),
	address: ({ address }) => rules.required(address),
});
