export const CHART_TYPE = {
	area: 'area',
	candlestick: 'candlestick',
	line: 'line',
	bars: 'bars',
} as const;

export const CHART_INTERVAL = {
	'1sec': '1sec',
	'5sec': '5sec',
	'15sec': '15sec',
	'30sec': '30sec',
	'1min': '1min',
	'5min': '5min',
	'15min': '15min',
	'30min': '30min',
	'1h': '1h',
	'3h': '3h',
	'1d': '1d',
	'30d': '30d',
} as const;

export const CHART_CANDLE_SIZE = {
	'5sec': '5sec',
	'5min': '5min',
	'10sec': '10sec',
	'10min': '10min',
	'15sec': '15sec',
	'15min': '15min',
	'30sec': '30sec',
	'30min': '30min',
	'1min': '1min',
	'1h': '1h',
	'2min': '2min',
	'2h': '2h',
	'4h': '4h',
	'8h': '8h',
	'1d': '1d',
	'1w': '1w',
	'1m': '1m',
} as const;

export const CHART_DRAWING = {
	pitchfork: 'pitchfork',
	fib_retracement: 'fib_retracement',
	fib_speed_resist_fan: 'fib_speed_resist_fan',
	horizontal_line: 'horizontal_line',
	parallel_channel: 'parallel_channel',
	ray: 'ray',
	rectangle: 'rectangle',
	trend_line: 'trend_line',
	vertical_line: 'vertical_line',
} as const;

export const CHART_INDICATORS = {
	sma: 'sma',
	ssma: 'ssma',
	lwma: 'lwma',
	ema: 'ema',
	sar: 'sar',
	alligator: 'alligator',
	bollingerBands: 'bollingerBands',
	rsi: 'rsi',
	stochastic: 'stochastic',
} as const;

export const CHART_PERIOD_TYPE = {
	seconds: 'seconds',
	minutes: 'minutes',
	hours: 'hours',
	days: 'days',
} as const;
